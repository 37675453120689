body {
  background-color: hsla(213, 77%, 5%, 1);
  background-image: radial-gradient(
      at 22% 32%,
      hsla(240, 37%, 34%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 77% 27%, hsla(352, 68%, 49%, 1) 0px, transparent 50%),
    radial-gradient(at 47% 77%, hsla(47, 100%, 62%, 0.75) 0px, transparent 50%),
    radial-gradient(at 77% 66%, hsla(238, 87%, 31%, 1) 0px, transparent 50%);
  background-size: 400% 400%;
  -webkit-animation: gradient 15s ease infinite;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
